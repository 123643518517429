<template>
  <PurchaseTab style="margin:0 auto;width:1300px" />
</template>

<script>
import PurchaseTab from '@/components/create-release/PurchaseTab.vue';

export default {
  name: 'Purchase',
  components: {
    PurchaseTab,
  },
};
</script>
