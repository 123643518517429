<template>
  <div>
    <el-dialog
      v-model="visible"
      class="purchase-modal"
      style="width:27.77777777777778em"
      @close="close"
    >
      <template #header="{ }">
        <div>
          Ваш заказ
        </div>
      </template>
      <div style="display: flex; flex-direction: row; align-items: center;justify-content: space-between; width:100%">
        <div style="font-size: 1em;  flex-basis: 170px;">
          Тариф:
          {{ tarifName }}
        </div>
        <div style="font-size: 1em;">
          1
        </div>
        <div style="font-size: 1.3333333333333333em;">
          {{ price }} ₽
        </div>
      </div>

      <template #footer>
        <div style="display: flex; flex-direction: row; align-items: center;justify-content: space-between; width:100%" class="mb3">
          <div style="font-size: 1em; flex-basis: 182px;">
            Общая стоимость
          </div>
          <div style="font-size: 1em;">
            1
          </div>
          <div style="font-size: 1.3333333333333333em;">
            {{ price }} ₽
          </div>
        </div>
        <el-checkbox v-model="allow" class="mb3">
          <template #default>
            Я ознакомлен с условиями <a style="text-decoration: none;" href="/files/oferta.pdf" target="_blank">оферты</a>
          </template>
        </el-checkbox>
        <div class="purchase-modal__btn">
          <el-button type="primary" :disabled="!allow">
            Подтвердить
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: 'PurchaseModal',
  props: {
    tarifName: String,
    modelValue: Boolean,
    price: String,
  },
  data: () => ({
    allow: false,
  }),
  computed: {
    visible: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  methods: {
    close() {
      this.visible = false;
    },
  },

};
</script>

<style lang="scss">
  .purchase-modal{

    &__btn{

    }
    .el-dialog{
      &__header{
        font-size: 1.7777777777777777em;
        color:$primaryBlue;
        padding:1.6666666666666667em 2.5em  0.8333333333333334em 2.5em!important;
        text-align: left !important;
        border-bottom: $whiteOpacity 1px solid;
        margin:0;

        &btn{
          font-size: 0.8em;
          top: 26px !important;
          right: 1.6666666666666667em !important;
        }
      }

      &__body{
        padding:1.1111111111111112em 2.5em 1.1111111111111112em 1.6666666666666667em!important;
        border-bottom: $whiteOpacity 1px solid;
      }

      &__footer{
        padding:1.1111111111111112em 2.5em 1.6666666666666667em 1.6666666666666667em!important;
      }
    }
  }
</style>
